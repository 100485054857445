import React from 'react';
import styled from '@emotion/styled';
import Button from '../../components/Button';

const TagList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: .5rem;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const TagItem = styled.li`
  display: flex;
  flex-direction: column;
`;

const TagButton = styled(Button)`
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: center;
`;

const Tags = ({ tags }) => (
  <TagList>
    {tags.map(tag => {
      return (
        <TagItem key={tag.id}><TagButton href={tag.path} isFullWidth>{tag.title}</TagButton></TagItem>
      );
    })}
  </TagList>
);

export default Tags;
