import React from 'react';
import { graphql } from 'gatsby';
import PageLayout from '../components/PageLayout';
import MetaTags from '../components/MetaTags';
import Banner from '../components/Banner';
import Tags from '../components/Tags';
import { selectTags } from '../selectors/selectTags';

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "\/content/posts/" } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;

const TagsPage = ({
  data,
  location: {
    href
  }
}) => {
  const tags = selectTags(data);
  const title = 'Tags';
  const description = 'Filter all posts on Assortment by commonly used keywords in the Web Industry';

  return (
    <PageLayout>
      <MetaTags
        url={href}
        metaTitle={title}
        metaDescription={description}
      />
      <Banner
        title={title}
        link={{
          href: '/',
          text: 'View all posts'
        }}
      />
      <Tags tags={tags} />
    </PageLayout>
  );
};

export default TagsPage;
