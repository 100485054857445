import { deriveSlugFromString } from '../utils';

export const selectTags = ({
  allMarkdownRemark: {
    group = []
  } = []
}) => {
  return group.map(({
    fieldValue = '',
    totalCount = 0
  }) => {
    const slug = deriveSlugFromString(fieldValue);
    return {
      id: slug,
      title: fieldValue,
      path: `/tags/${slug}`,
      postCount: totalCount
    }
  });
}
