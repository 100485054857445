import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import { baseColors } from '../../constants';

const Button = ({
  children,
  href = '',
  onClick = () => {},
  type = 'button',
  isFullWidth = false,
  ...props
}) => {
  const ButtonStyles = css`
    display: ${isFullWidth ? 'block' : 'inline-block'};
    padding: .5rem 1rem;
    text-decoration: none;
    color: ${baseColors.white};
    background-color: ${baseColors.link.base};
    ${isFullWidth && 'text-align: center;'}
    cursor: pointer;
    transition: background-color .15s ease-out;

    &:hover, &:focus {
      background-color: ${baseColors.link.dark};
    }
  `;

  if (href) return <Link to={href} css={ButtonStyles} {...props}>{children}</Link>;
  return <button css={ButtonStyles} type={type} onClick={onClick} {...props}>{children}</button>;
};

export default Button;
